import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, Button, ConfirmationModal } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import { IconRemove } from 'shared/Icons';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import './styles.scss';

import RelatedInfoFields from 'shared/RelatedInfoFields/index';
import SalaryModal from './components/SalaryModal';

import { deleteWorkerSalary, getSalaries, getWorkerDetails } from '../../actions';
import { getAllDepartments, getPartners } from '../../../IndustryLocationHumanResources/actions';

import WorkerModalForm from '../../../IndustryLocationHumanResources/components/Workers/components/WorkerModalForm';

const GeneralInfoTab = ({ t, companyId, locationId, workerDetails = {}, transformString, isReadOnly, currentUserRole, customDepartment }) => {
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });
  const [relatedInfoKey, setRelatedInfoKey] = useState(Date.now());
  const [workerDetailsData, setWorkerDetailsData] = useState(workerDetails);
  const [editWorkerModalData, setEditWorkerModalData] = useState({
    isOpen: false,
    worker: null,
  });

  const [salaries, setSalaries] = useState({
    data: [],
    isLoading: true,
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);

  const getGenderLabel = () => {
    let label = '-';
    if (workerDetailsData?.gender === 'male') {
      label = t('page_content.workforce_management.worker_details.gender_male');
    }
    if (workerDetailsData?.gender === 'female') {
      label = t('page_content.workforce_management.worker_details.gender_female');
    }
    if (workerDetailsData?.gender === 'other') {
      label = t('page_content.workforce_management.worker_details.gender_other');
    }
    return label;
  };

  const fetchSalaries = () => {
    if (workerDetails?.id && currentUserRole !== 'Voditelj') {
      setSalaries((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const apiFilters = `&limit=100&order_by=-date&worker=${workerDetails?.id}`;

      getSalaries(companyId, apiFilters)
        .then((res) => {
          setSalaries({
            data: get(res, 'data.results', []),
            isLoading: false,
          });
        })
        .catch(() => {
          setSalaries((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        });
    }
  };

  const fetchDepartments = async () => {
    const res = await getAllDepartments(locationId);
    setDepartmentOptions(get(res, 'data.results', []));
  };

  const fetchCompanies = async () => {
    const res = await getPartners(companyId);
    setCompaniesOptions(get(res, 'data.results', []));
  };

  const fetchWorkersList = () => {
    getWorkerDetails(locationId, companyId, workerDetails?.id)
      .then((resp) => {
        const worker = get(resp, 'data', []);
        if (worker) {
          setWorkerDetailsData(worker);
          setRelatedInfoKey(Date.now());
        }
      });
  };

  useEffect(() => {
    fetchSalaries();
    fetchDepartments();
    fetchCompanies();
    setWorkerDetailsData(workerDetails);
  }, [workerDetails]);

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const handleAddNewSalary = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchSalaries();
  };

  const handleShowConfirmationDialog = (row, e) => {
    e.stopPropagation();
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteWorkerSalary(deleteData?.original?.id, companyId);
    setShowConfirmationDialog(false);
    fetchSalaries();
  };

  const handleCloseEditWorkerModal = () => {
    setEditWorkerModalData({
      isOpen: false,
      worker: null,
    });
  };

  const handleCloseAndRefetchWorkerModal = () => {
    handleCloseEditWorkerModal();
    fetchWorkersList();
  };

  return (
    <div className="workerDetails_generalInfoTable">
      <div className={currentUserRole !== 'Voditelj' ? 'general_info_table' : 'general_info_table_100'}>
        <div className="header">
          {t('page_content.workforce_management.worker_details.general_info')}
          <Button
            type="add"
            disabled={isReadOnly}
            onClick={() => setEditWorkerModalData({ isOpen: true, worker: workerDetails })}
          >
            {t('page_content.workforce_management.worker_details.edit_worker_details_button')}
          </Button>
        </div>
        <div className="info_table_content">
          <div className="row">{t('page_content.workforce_management.worker_details.general_full_name')}:<span>{workerDetailsData ? `${transformString(workerDetailsData.name)} ${transformString(workerDetailsData.last_name)}` : '-'}</span></div>
          <div className="row">{t('page_content.workforce_management.worker_details.general_gender')}:<span>{getGenderLabel()}</span></div>
          <div className="row">{t('page_content.human_resources.workers.column_age_years')}:<span>{workerDetailsData?.date_of_birth ? moment().diff(moment(workerDetailsData?.date_of_birth), 'years') : '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_birthday')}:<span>{workerDetailsData?.date_of_birth ? moment(workerDetailsData.date_of_birth).format(defaultDateFormat) : '-'}</span></div>
          <div className="row">{t('page_content.workforce_management.worker_details.general_email')}:<span>{workerDetailsData?.email || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.table_column_private_email')}:<span>{workerDetailsData?.private_email || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_mobile')}: <span>{workerDetailsData?.mobile_phone || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_tax')}: <span>{workerDetailsData?.tax_id || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.table_column_address')}:<span>{workerDetailsData?.address || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_qualifications')}:<span>{workerDetailsData?.qualification?.name || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_profession')}:<span>{workerDetailsData?.profession?.name || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.table_column_department')}: <span>{workerDetailsData?.department ? workerDetailsData.department : workerDetailsData?.location_department ? workerDetailsData?.location_department?.name : '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.default_shift')}:<span>{workerDetailsData?.department_shift ? `${workerDetailsData?.department_shift?.name} (${moment(workerDetailsData?.department_shift?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(workerDetailsData?.department_shift?.end_time, 'HH:mm:ss').format('HH:mm')})` : '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.table_column_active_from')}:<span>{workerDetailsData?.active_from ? moment(workerDetailsData.active_from).format(defaultDateFormat) : '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.column_seniority')}:<span>{workerDetailsData?.seniortiy_years || '-'}{t('page_content.human_resources.workers.column_seniority_in_y')} {workerDetailsData?.seniority_months || '-'}{t('page_content.human_resources.workers.column_seniority_in_m')} {workerDetailsData?.seniority_days || '-'}{t('page_content.human_resources.workers.column_seniority_in_d')}</span></div>
          <div className="row">{t('page_content.human_resources.workers.column_seniority_changed')}:<span>{workerDetailsData?.seniority_date ? moment(workerDetailsData.seniority_date).format(defaultDateFormat) : '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.column_kfk_service')}:<span>{workerDetailsData?.kfk_service || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_transport_amount')}:<span>{workerDetailsData?.transport_fee || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_food_amount')}:<span>{workerDetailsData?.food_fee || '-'}</span></div>
          <div className="row">{t('page_content.human_resources.workers.input_label_work_permit')}:<span>{workerDetailsData?.work_permit_valid_until ? moment(workerDetailsData?.work_permit_valid_until).format(defaultDateFormat) : '-'}</span></div>

          <RelatedInfoFields key={relatedInfoKey} companyId={companyId} workerId={workerDetails?.id} isWorkerDetails />

          <div className="row">{t('page_content.human_resources.workers.input_label_info')}:<span>{workerDetailsData?.note || '-'}</span></div>
        </div>
      </div>
      {
        currentUserRole !== 'Voditelj' &&
        <div className="salary_table">
        <div className="header">
          <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_salary')}</span>
          <Button
            disabled={isReadOnly}
            type="add"
            onClick={handleAddNewSalary}
          >
            {t('page_content.human_resources.workers.salary_increase_table.add_salary')}
          </Button>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.workers.salary_increase_table.table_is_active')}</span>,
              accessor: 'is_active',
              Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>
                {row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}
                </div>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_date')}</span>,
              accessor: 'date',
              Cell: (row) => <span>{row.value ? moment(row.value).format(defaultDateFormat) : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_net_salary')}</span>,
              accessor: 'net_salary',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_gross_salary')}</span>,
              accessor: 'gross_salary',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_gross_salary_2')}</span>,
              accessor: 'gross_salary_2',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.workers.salary_increase_table.table_column_increase')}</span>,
              accessor: 'percent',
              Cell: (row) => <span style={{ color: parseFloat(row?.value) > 0 ? 'green' : 'red' }}>
                {row.value ? row.value : '-'}
              </span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button
                  type="delete"
                  onClick={(e) => handleShowConfirmationDialog(row, e)}
                >
                  <IconRemove width="12px" height="12px" />
                </Button>
              ),
              width: 80,
              sortable: false,
            },
          ]}
          isCompact
          data={salaries?.data}
          minRows={0}
          selectedRow={null}
          defaultPageSize={100}
          noDataText=" "
          showPagination={false}
          sortable={false}
          loading={salaries?.isLoading}
        />
      </div>
      }

      {
        editWorkerModalData?.isOpen &&
        <WorkerModalForm
          t={t}
          initialData={editWorkerModalData?.worker}
          companyId={companyId}
          showModal={editWorkerModalData?.isOpen}
          locationId={locationId}
          handleClose={handleCloseEditWorkerModal}
          departments={departmentOptions}
          customDepartment={customDepartment}
          companiesOptions={companiesOptions}
          handleCloseAndRefetch={handleCloseAndRefetchWorkerModal}
        />
      }

      {modalData?.isOpen &&
        <SalaryModal
          workerDetails={workerDetails}
          isOpen={modalData?.isOpen}
          initialValues={modalData?.selectedItem}
          handleCloseModal={handleCloseModal}
          closeModalAndRefetch={closeModalAndRefetch}
        />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'original.date', '')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

GeneralInfoTab.propTypes = {
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  currentUserRole: PropTypes.string,
  customDepartment: PropTypes.string,
  locationId: PropTypes.number.isRequired,
  transformString: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  workerDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

function mapStateToProps(state) {
  return {
    customDepartment: get(state, 'app.companyConfig.config.custom_department'),
  };
}

export default connect(mapStateToProps)(withRouter((withTranslation()(GeneralInfoTab))));
