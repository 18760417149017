/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import { deleteBudget, getAllDepartments, getBudgets, getPaginatedBudgets } from '../actions';
import '../styles.scss';
import DepartmentBudgetModal from './DepartmentBudgetModal';

const DepartmentBudgetTable = ({ t, companyId, locationId }) => {
  const [tableData, setTableData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });
  const [filters, setFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'year',
    departmentFilter: '',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const [departmentOptions, setDepartmentOptions] = useState([]);

  const fetchDepartments = async () => {
    const res = await getAllDepartments(locationId);
    setDepartmentOptions(get(res, 'data.results', []));
  };

  const fetchDepartmentBudget = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `&company=${companyId}&department_isnull=false&profession_isnull=true&limit=15&order_by=${asc}${filters?.selectedSort}`;

    if (filters?.departmentFilter) {
      apiFilters += `&department=${filters?.departmentFilter?.id}`;
    }

    getBudgets(apiFilters)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedDepartmentBudget = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedBudgets(url)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchDepartmentBudget();
  }, [companyId, filters]);

  useEffect(() => {
    fetchDepartments();
  }, [locationId]);

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewDepartmentBudget = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const clearAllFilters = () => {
    setFilters((prevState) => ({
      ...prevState,
      departmentFilter: '',
    }));
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchDepartmentBudget();
  };

  const handleShowConfirmationDialog = (row, e) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteBudget(deleteData?.original?.id, companyId);
    setShowConfirmationDialog(false);
    fetchDepartmentBudget();
  };

  return (
    <React.Fragment>
      <div className="department-budget">
        <h3>{t('page_content.administration.control_panel_tab.department_budget_heading')}</h3>

        <div className="department-budget__action-buttons">
          <Button
            type="add"
            onClick={handleAddNewDepartmentBudget}
          >
            {t('page_content.administration.control_panel_tab.add_new_department_budget_button')}
          </Button>
        </div>

        <div className="department-budget__filters__dropdowns">
          <Select
            className="select-style"
            options={departmentOptions.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.administration.filter_by_department')}
            isClearable
            onChange={(e) => handleFilterChange('departmentFilter', e)}
            value={departmentOptions.find((a) => a.id === filters?.departmentFilter?.id) || ''}
            styles={selectModalStyles}
          />
          <div className="department-budget__filters__dropdowns__clear">
            <Button type="plain" onClick={clearAllFilters}>{t('page_content.administration.clear_all')}</Button>
          </div>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.net_salary_column')}</span>,
              accessor: 'net_salary',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.non_taxable_column')}</span>,
              accessor: 'non_taxable',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.gross_salary_column')}</span>,
              accessor: 'gross_salary',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.gross_salary_2_column')}</span>,
              accessor: 'gross_salary_2',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.department_column')}</span>,
              accessor: 'department.name',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.year_column')}</span>,
              accessor: 'year',
            },
          ]}
          data={tableData?.data || []}
          defaultPageSize={100}
          loading={tableData.isLoading}
          minRows={0}
          noDataText=""
          enableEdit
          enableDelete
          onEdit={(row) => handleTableRowClick(row)}
          onDelete={(original) => handleShowConfirmationDialog(original)}
          defaultSorted={[{ id: 'year', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          previous={tableData.previous}
          next={tableData.next}
          fetchFunction={fetchPaginatedDepartmentBudget}
          count={tableData.count}
        />
      </div>

      {modalData?.isOpen &&
        <DepartmentBudgetModal
          t={t}
          isOpen={modalData?.isOpen}
          initialValues={modalData?.selectedItem}
          handleCloseModal={handleCloseModal}
          closeModalAndRefetch={closeModalAndRefetch}
          departmentOptions={departmentOptions}
        />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'year')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </React.Fragment>
  );
};

DepartmentBudgetTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withTranslation()(DepartmentBudgetTable);
