import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { IconRemove, IconEdit } from 'shared/Icons';
import { defaultDateFormat } from 'shared/constants';
import { Table, TableButtons, Button, ConfirmationModal } from 'shared';

import { getParameterGoals, getPaginatedParameterGoals, deleteParameterGoal } from '../actions';
import '../styles.scss';

import ParameterGoalsModal from './ParameterGoalsModal';

const ParameterGoals = ({ t, isReadOnly, companyId, locationId }) => {
  const [goals, setGoals] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [filters, setFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'name',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchGoals = () => {
    setGoals((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `company=${companyId}&location=${locationId}&limit=15&order_by=${asc}${filters?.selectedSort}`;

    getParameterGoals(apiFilters)
      .then((res) => {
        setGoals({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setGoals((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedGoals = (url) => {
    setGoals((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedParameterGoals(url)
      .then((res) => {
        setGoals({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setGoals((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchGoals();
  }, [companyId, filters]);

  const handleShowConfirmationDialog = (row, e) => {
    e.stopPropagation();
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteParameterGoal(deleteData?.original?.id, companyId);
    setShowConfirmationDialog(false);
    fetchGoals();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewGoal = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchGoals();
  };

  return (
    <div className="parameter-goals">
      <div className="parameter-goals__table">
        <h3>{t('page_content.workforce_management.parameter_goals')}</h3>

        <div className="parameter-goals__action-buttons">
        <Button
          type="add"
          onClick={handleAddNewGoal}
          disabled={isReadOnly}
        >
          {t('page_content.workforce_management.add_new_goal')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.workforce_management.definition')}</span>,
              accessor: 'definition.name',
            },
            {
              Header: () => <span>{t('page_content.workforce_management.value')}</span>,
              accessor: 'value',
            },
            {
              Header: () => <span>{t('page_content.workforce_management.valid_from')}</span>,
              accessor: 'valid_from',
              Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : ''),
            },
            {
              Header: () => '',
              Cell: (row) => (
              <Button
                disabled={isReadOnly}
                onClick={() => handleTableRowClick(row)}
              >
                <IconEdit width="12px" height="12px" /></Button>),
              width: 70,
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button
                  type="delete"
                  onClick={(e) => handleShowConfirmationDialog(row, e)}
                  disabled={isReadOnly}
                >
                  <IconRemove width="12px" height="12px" />
                </Button>
              ),
              width: 70,
              sortable: false,
            },
          ]}
          minRows={0}
          noDataText=""
          defaultPageSize={100}
          data={goals.data || []}
          loading={goals.isLoading}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
          <span style={{ float: 'right' }}>
            <TableButtons
              next={goals.next}
              count={goals.count}
              previous={goals.previous}
              fetchFunction={fetchPaginatedGoals}
            />
          </span>
      </div>

      {modalData?.isOpen &&
      <ParameterGoalsModal
        isOpen={modalData?.isOpen}
        handleCloseModal={handleCloseModal}
        initialValues={modalData?.selectedItem}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

ParameterGoals.propTypes = {
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

export default ParameterGoals;
